import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {CRQ_FORM_FOCUS, FILE_POSTFIX, KEY_POSTFIX} from '../common/constants'
import LoadingOverlay from "../common/components/SmartLoadingOverlay";
import {customValidityForDropdowns, millisecondsSinceEpochStart} from "../common/commonUtilities";
import {uploadData} from "aws-amplify/storage";

const NO_CRQ_DETAILS = new FormData()
const SUBMIT_ON_CRQ_FORM = 'submit_on_crq_form'
const FILE_UPLOAD_FOLDER = 'batch_request_inputs/'

// the modal form for entering CRQ details and confirming selection
export default function CrqForm({requestDetails, show, submitCrq, onHide}) {

  const [validated, setValidated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [crqFormDetails, setCrqFormDetails] = useState(NO_CRQ_DETAILS);

  const setFocus = () => {
    // If the Service Request doesn't contains additional fields, focus on the Confirm button (stated as the Button ID attribute rendered in the Modal Footer)
    if (requestDetails.params.props.jsx.includes("There are no additional parameters for this action")) {
      document.getElementById(SUBMIT_ON_CRQ_FORM).focus()
      // If the Service Request contains additional parameters, focus on the first element.
      // Ternary ensures name attribute for focus in any given SmartXXX.js module is caught and focused on.
    } else {
      const focus_thing = document.getElementsByName(CRQ_FORM_FOCUS)[0].children[0];
      ((focus_thing.children.length > 0) && (typeof (focus_thing.type) === "undefined")) ? focus_thing.children[0].focus() : focus_thing.focus()
    }
  }

  // clear all the submitted details
  const handleExit = () => {
    setValidated(false);
    setSubmitting(false)
    setCrqFormDetails(NO_CRQ_DETAILS);
  }

  const handleFileUploads = async (fileUploads, crqForm, idNo) => {
    for (const element of fileUploads) {
      const file = element.files[0];
      const key = (FILE_UPLOAD_FOLDER + idNo + '/' + file.name).replaceAll(/[&$@=;+,\s?\\{}^%`[\]"><#|~]/g, '_');
      crqForm.append(element.name.split(FILE_POSTFIX)[0] + KEY_POSTFIX, key);

      const upload = uploadData({
        key,
        data: file,
        options: {
          contentType: file.type
        }
      });
      try {
        await upload.result;
      } catch (e) {
        console.log(e);
        crqForm.append('front_end_file_upload_error', e)
      }
    }
    setCrqFormDetails(crqForm);
  };

  // check validity and set the form to display errors if there are any. Otherwise, call the parent's submitCrq function withe details from the form
  const handleSubmit = async (event) => {
    event.preventDefault();
    customValidityForDropdowns();
    if (!event.target.checkValidity()) {
      setValidated(true)
    } else {
      setValidated(false);
      setSubmitting(true);
      // if there are files to upload, we need to append the id_no and s3 key to the form, so create the form first
      let crqForm = new FormData(event.target);
      const fileUploads = [...event.target.elements].filter(element => element.files);
      // if there are any fileUpload parameters, upload the files to S3 and set id_no / S3 Keys in the form
      if (fileUploads.length > 0) {
        const idNo = millisecondsSinceEpochStart();
        crqForm.append('id_no', idNo);

        await handleFileUploads(fileUploads, crqForm, idNo)
      } else {
        // avoid multiple submits - will only work on the first submit
        setCrqFormDetails(crqForm)
      }
    }
  }

  const handleCancel = () => {
    handleExit()
    onHide()
  }

  // only submit anything if the details have been populated, so through submit button
  useEffect(() => {
    (crqFormDetails === NO_CRQ_DETAILS) ? setSubmitting(false) : submitCrq(crqFormDetails)
  }, [crqFormDetails])

  return (
    <Modal
      show={show}
      onHide={handleCancel}
      onExited={handleExit}
      onEntering={setFocus}
      size={requestDetails?.params?.props?.jsx?.includes("SmartCalendar") ? "xl" : "lg"}
      centered
      backdrop='static'>
      <LoadingOverlay active={submitting}>
        <Form noValidate onSubmit={handleSubmit} className={validated ? "was-validated" : ""}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Requested action
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row} className="p-2" >
              <Form.Label column sm="3">Email:</Form.Label>
              <Col sm="9"><Form.Control
                id="email"
                name="email"
                value={requestDetails.email}
                plaintext
                readOnly
              /></Col>
            </Form.Group>
            <Form.Group as={Row} className="p-2">
              <Form.Label column sm="3">Device:</Form.Label>
              <Col sm="9"><Form.Control
                id="target_device_id"
                name="target_device_id"
                value={requestDetails.device_id}
                plaintext
                readOnly
              /></Col>
            </Form.Group>
            <Form.Group as={Row} className="p-2">
              <Form.Label column sm="3">Action:</Form.Label>
              <Col sm="9"><Form.Control
                id="crq_name_with_spaces"
                name="crq_name_with_spaces"
                value={requestDetails.crq_name}
                plaintext
                readOnly
              /></Col>
            </Form.Group>
            <Form.Group as={Row} className="p-2" hidden>
              <Form.Label column sm="3">Action:</Form.Label>
              <Col sm="9"><Form.Control
                id="crq_name"
                name="crq_name"
                value={requestDetails.crq}
                plaintext
                readOnly
              /></Col>
            </Form.Group>
            <Form.Group as={Row} className="p-2" hidden>
              <Form.Label column sm="3">Client ID</Form.Label>
              <Col sm="9"><Form.Control
                id="client_id"
                name="client_id"
                value={requestDetails.client_id}
                plaintext
                readOnly
              /></Col>
            </Form.Group>
            <Form.Group as={Row} className="p-2" hidden>
              <Form.Label column sm="3">Client Name</Form.Label>
              <Col sm="9"><Form.Control
                id="client_name"
                name="client_name"
                value={requestDetails.client_name}
                plaintext
                readOnly
              /></Col>
            </Form.Group>
            <p><u>Additional parameters:</u></p>
            {!submitting && requestDetails.params}
          </Modal.Body>
          {!submitting && <Modal.Footer>
            <Button onClick={handleCancel} variant="secondary">Cancel</Button>
            <Button id={SUBMIT_ON_CRQ_FORM} type="submit" variant="primary">Confirm</Button>
          </Modal.Footer>}
        </Form>
      </LoadingOverlay>
    </Modal>
  );
}

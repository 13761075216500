import React from "react";
import moment from 'moment';
import SmartDateTime from "./SmartDateTime";

export default function SpScheduledDateTime() {
  const endOfToday      = moment.utc().add(1, 'days').startOf('day');
  const now             = moment.utc();
  const thirtyDaysLater = moment.utc().add(30, 'days');
  return (
    <SmartDateTime
      name="sp_scheduled"
      label="Smart Pear processing begins at UTC date-time?"
      dateTimeDefault={endOfToday}
      minDateTime={now}
      maxDateTime={thirtyDaysLater}
      optional={true}
    />
  )
}